function getDefaultValue (variable) {
  switch (variable) {
    case 'language': {
      const lang = localStorage.getItem('currentLanguage')

      if (lang) {
        return JSON.parse(lang)
      } else {
        return {
          title: 'Romana/Romania',
          countryCode: 'RO',
          languageCode: 'ro'
        }
      }
    }
  }
}

export { getDefaultValue }
