<template>
  <rhb-index />
</template>

<script>
// @ is an alias to /src
import Index from '@/components/Index.vue'

export default {
  name: 'reabilitare-home',
  components: {
    'rhb-index': Index
  }
}
</script>
