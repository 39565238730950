<template>
  <v-app>
    <v-navigation-drawer
      :clipped="true"
      temporary
      v-model="drawer"
      app
      width="auto"
      style="padding-top: 64px">
      <v-list shaped nav>
        <v-list-item-group v-model="selectedMenuItem" color="primary">
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'Home' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.home') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'APLs' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-map</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.apluri') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'PropertyGroups' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-map-marker-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.grupuriProprietatiMarkere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'Properties' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-map-marker-question</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.proprietatiMarkere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'Categories' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-map-marker-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.categoriiMarkere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'Statuses' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-map-marker-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.statusuriMarkere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'Markers' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.markere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'UserTypes' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-account-convert</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.tipuriUtilizatori') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'Users' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.utilizatori') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'Dictionary' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-book-alphabet</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.dictionar') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :to="{ name: 'Language' }" exact>
              <v-list-item-icon>
                <v-icon>mdi-sign-language</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.limbiDictionar') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      :clipped-left="true"
      style="flex-grow: 0; z-index: 8"
      color="grey lighten-3">
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="user">
        <slot>
          <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-menu</v-icon>
        </slot>
      </v-app-bar-nav-icon>

      <template v-if="apl && apl.website && apl.website.show">
        <a :href="apl.website.url" target="_blank">
          <v-avatar class="mr-4" :height="$vuetify.breakpoint.xsOnly ? 36 : 48" width="auto" tile>
            <img :src="getURL()">
          </v-avatar>
        </a>
      </template>

      <v-spacer></v-spacer>
      <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.app.title') }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <rhb-i18n />

      <v-btn :small="$vuetify.breakpoint.xsOnly" icon @click="showLogSheet" v-if="user" :title="$vuetify.lang.t('$vuetify.app.logTitle')">
        <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-bell</v-icon>
      </v-btn>
      <v-btn :small="$vuetify.breakpoint.xsOnly" icon @click="showLoginForm" v-if="!user" :title="$vuetify.lang.t('$vuetify.app.authTitle')">
        <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-login</v-icon>
      </v-btn>
      <v-btn :small="$vuetify.breakpoint.xsOnly" icon @click="logout" v-else :title="$vuetify.lang.t('$vuetify.app.logoutTitle')">
        <v-icon :small="$vuetify.breakpoint.xsOnly">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-dialog
        persistent
        v-model="loginForm"
        width="500">
        <v-card>
          <v-card-title>
            <span>{{ $vuetify.lang.t('$vuetify.app.loginForm.title') }}</span>
            <v-spacer></v-spacer>
            <v-btn icon small>
              <v-icon small color="error" @click="closeLoginForm">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form v-model="validForm" ref="form">
              <v-text-field
                v-model="email"
                :label="$vuetify.lang.t('$vuetify.app.loginForm.emailLabel')"
                required
                :rules="[required]">
              </v-text-field>
              <v-text-field
                :type="showPass ? 'text' : 'password'"
                v-model="password"
                :label="$vuetify.lang.t('$vuetify.app.loginForm.passwordLabel')"
                required
                :rules="[required]"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass">
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="closeLoginForm">{{ $vuetify.lang.t('$vuetify.app.loginForm.cancelLabel') }}</v-btn>
            <v-btn color="primary" @click="login">{{ $vuetify.lang.t('$vuetify.app.loginForm.confirmLabel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view />

      <v-bottom-sheet v-model="showLog" scrollable>
        <v-card>
          <v-card-title>
            <span>{{ $vuetify.lang.t('$vuetify.log.title') }}</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="logSearch"
              :label="$vuetify.lang.t('$vuetify.log.searchTerm')"
              :hint="$vuetify.lang.t('$vuetify.log.searchTermHint')"
              clearable>
            </v-text-field>
            <v-spacer></v-spacer>
            <v-menu
              :open-on-hover="false"
              :close-on-click="true"
              :close-on-content-click="true">
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  color="primary"
                  v-on="on">
                  <v-icon left>mdi-filter</v-icon>
                  {{ $vuetify.lang.t('$vuetify.log.tipMesaje') }} ({{ $vuetify.lang.t(`$vuetify.log.tipMesaje-${logType}`) }})
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="logType in logTypes"
                  :key="logType"
                  @click="setLogType(logType)">
                  <v-list-item-icon>
                    <v-icon :color="logType === 'all' ? 'grey' : logType">{{getIcon(logType)}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $vuetify.lang.t(`$vuetify.log.tipMesaje-${logType}`) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-menu
              :open-on-hover="false"
              :close-on-click="true"
              :close-on-content-click="true">
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  color="primary"
                  v-on="on">
                  <v-icon left>mdi-page</v-icon>
                  {{ $vuetify.lang.t('$vuetify.dataTable.itemsPerPageText') }} ({{logLimit}})
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="limit in [10, 20, 50, 100]"
                  :key="limit"
                  @click="setLogLimit(limit)">
                  <v-list-item-content>
                    <v-list-item-title>{{ limit }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn icon @click="clearLog">
              <v-icon color="error">
                mdi-delete-sweep
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon small>
              <v-icon color="red" @click="showLog = false">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="max-height: 75vh">
            <v-list dense>
              <v-list-item
                v-for="msg in logItems"
                :key="msg._id"
                two-line>
                <v-list-item-icon>
                  <v-icon :color="msg.type === 'all' ? 'grey' : msg.type">{{getIcon(msg.type)}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ msg.text }}</v-list-item-title>
                  <v-list-item-subtitle>{{ (new Date(msg.createdAt)).toLocaleString('ro-RO') }} - {{ msg.user }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="deleteLogItem(msg._id)">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              v-model="logPage"
              :length="logTotalPages"
              :total-visible="7"
              circle>
            </v-pagination>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-bottom-sheet>
      <a href="https://tudconsult.ro" target="_blank" style="position: absolute; bottom: 50px; left: 20px; background: white; padding: 12px">
        <v-avatar class="mr-4" :height="$vuetify.breakpoint.xsOnly ? 36 : 48" width="auto" tile>
          <img src="/logos/logo-tud.png">
        </v-avatar>
      </a>
    </v-main>
  </v-app>
</template>

<script>
import _debounce from 'lodash.debounce'
import SERVER_URL from '@/config'
import RhbI18n from '@/components/I18n.vue'

export default {
  name: 'App',
  components: {
    'rhb-i18n': RhbI18n
  },
  data: () => ({
    SERVER_URL,
    logTypes: ['all', 'success', 'info', 'warning', 'error'],
    validForm: true,
    drawer: false,
    loginForm: false,
    email: null,
    password: null,
    showPass: false,
    showLog: false,
    selectedMenuItem: null
  }),

  computed: {
    user () {
      return this.$store.getters.user
    },
    logItems () {
      return this.$store.getters.logItems
    },
    logType () {
      return this.$store.getters.logType
    },
    logLimit () {
      return this.$store.getters.logLimit
    },
    logPage: {
      get () {
        return this.$store.getters.logPage
      },
      set (value) {
        this.$store.dispatch('setLogItemsPage', value)
      }
    },
    logTotalPages () {
      return this.$store.getters.logTotalPages
    },
    logSearch: {
      get () {
        return this.$store.getters.logSearch
      },
      set: _debounce(function (value) {
        this.$store.dispatch('setLogSearch', value)
      }, 500)
    },
    apl () {
      return this.$store.getters.currentAPL
    }
  },

  methods: {
    required (val) {
      return (val !== null && val !== undefined) || this.$vuetify.lang.t('$vuetify.requiredField')
    },
    getIcon (type) {
      switch (type) {
        case 'all': return 'mdi-text-box'
        case 'success': return 'mdi-check'
        case 'info': return 'mdi-information-outline'
        case 'warning': return 'mdi-alert-circle-outline'
        case 'error': return 'mdi-close'
      }
    },
    setLogLimit (limit) {
      this.$store.dispatch('setLogItemsLimit', limit)
    },
    setLogType (type) {
      this.$store.dispatch('setLogItemsType', type)
    },
    showLogSheet () {
      this.showLog = true
    },
    showLoginForm () {
      this.loginForm = true
    },
    closeLoginForm () {
      this.loginForm = false
      this.$refs.form.reset()
    },
    login () {
      this.$refs.form.validate()
      if (this.validForm) {
        this.$store.dispatch('login', { email: this.email, password: this.password })
        this.closeLoginForm()
      }
    },
    logout () {
      this.$store.dispatch('logout')
      if (this.$route.name !== 'Home') this.$router.push({ name: 'Home' })
    },
    deleteLogItem (id) {
      this.$store.dispatch('deleteLogItem', id)
    },
    clearLog () {
      this.$store.dispatch('deleteLogItem', null)
    },

    async getAPL () {
      const apl = await this.$store.dispatch('getAPL', this.$route.params.id)
      this.apl = Object.freeze(apl)
    },
    getURL () {
      return this.SERVER_URL + this.apl.logo.url
    },

    dummy () {}
  },

  beforeMount () {
    this.$store.dispatch('reAuth')
    this.$store.dispatch('fetchLanguage')
  }
}
</script>

<style lang="stylus">
  .line-clamp-3 {
    overflow:hidden; display:-webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp:3;
  }

  .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
    overscroll-behavior: contain;
  }

  .v-pagination__item {
    outline: none !important;
  }

  .rhb-fab {
    bottom: 0;
    right: 0 !important;
    position: fixed !important;
    margin: 0 16px 16px 0;
    z-index: 1;
  }

  th[role="columnheader"] {
    white-space: nowrap;
  }

  .table-cell-wrap {
    white-space: normal;
  }
  .table-cell-no-wrap {
    white-space: nowrap;
  }

  .rhb-dict-term:not(:last-child) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    margin-bottom: .25em;
  }
</style>
