var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.mapCenter,"zoom":7,"map-type-id":"roadmap","options":{styles: _vm.mapStyles, tilt: _vm.tilt }}},[_vm._l((_vm.apls),function(apl){return [_c('GmapMarker',{key:apl._id + 'marker',attrs:{"position":apl.center,"clickable":true,"label":{ text: apl.icon.showLabel ? _vm.$vuetify.lang.t(`$vuetify.${apl.name}`) +', ' + _vm.$vuetify.lang.t(`$vuetify.${apl.county}`) : ' ', fontWeight: 'normal' },"icon":{
          url: _vm.getIcon(apl.icon),
          scaledSize: { width: 40 * apl.icon.scale, height: 60 * apl.icon.scale },
          origin: { x: 0, y: 0 },
          anchor: { x: (40 * apl.icon.scale) / 2, y: 60 * apl.icon.scale },
          labelOrigin: { x: (40 * apl.icon.scale) / 2, y: 60 * apl.icon.scale + 10 }
        }},on:{"click":function($event){return _vm.showApl(apl)}}}),_c('GmapPolygon',{key:apl._id + 'poly',attrs:{"paths":_vm.decodePath(apl),"options":_vm.polyOptions(apl)}})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }