import Vue from 'vue'
import Vuex from 'vuex'
import _debounce from 'lodash.debounce'
import { getDefaultValue } from '@/utilities'

import feathers from '@/feathers/'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    feathers,
    services: {
      auth: feathers.service('authentication'),
      users: feathers.service('users'),
      log: feathers.service('log'),
      apls: feathers.service('apls'),
      statuses: feathers.service('statuses'),
      categories: feathers.service('categories'),
      markers: feathers.service('markers'),
      userTypes: feathers.service('usertypes'),
      propertyGroups: feathers.service('propertygroups'),
      properties: feathers.service('properties'),
      details: feathers.service('details'),
      dictionary: feathers.service('dictionary'),
      i18n: feathers.service('i18n'),
      language: feathers.service('language'),
      rest: feathers.service('rest'),
      geojson: feathers.service('geojson')
    },
    auth: {
      user: null,
      token: null
    },
    log: {
      items: [],
      page: 1,
      limit: 10,
      totalPages: 10,
      type: 'all',
      search: null
    },
    apls: {
      items: [],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: null
    },
    statuses: {
      items: [],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: null,
      apl_id: null,
      category_id: null
    },
    categories: {
      items: [],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: null
    },
    markers: {
      items: [],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: null,
      apl_id: null,
      category_id: null,
      status_id: null,
      availability: null
    },
    userTypes: {
      items: [],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: null
    },
    propertyGroups: {
      items: [],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: null
    },
    properties: {
      items: [],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: null
    },

    currentLanguage: getDefaultValue('language'),

    currentAPL: null,

    dictionary: {
      keys: [],
      items: [],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: null
    },

    language: {
      keys: [],
      items: [],
      total: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: null
    },

    dictionaryGeneration: {
      loading: false,
      complete: false
    },
    dictionaryImportProgress: {
      value: 0,
      visible: false
    },

    uniquesForApl: []
  },
  getters: {
    user: state => state.auth.user,

    logItems: state => state.log.items,
    logType: state => state.log.type,
    logLimit: state => state.log.limit,
    logPage: state => state.log.page,
    logTotalPages: state => state.log.totalPages,
    logSearch: state => state.log.search,

    aplItems: state => state.apls.items,
    aplsTotal: state => state.apls.total,
    aplsLoading: state => state.apls.loading,
    aplsOptions: state => state.apls.options,
    aplsSearch: state => state.apls.search,

    statusesItems: state => state.statuses.items,
    statusesTotal: state => state.statuses.total,
    statusesLoading: state => state.statuses.loading,
    statusesOptions: state => state.statuses.options,
    statusesSearch: state => state.statuses.search,
    statusesAPL: state => state.statuses.apl_id,
    statusesCategory: state => state.statuses.category_id,

    categoriesItems: state => state.categories.items,
    categoriesTotal: state => state.categories.total,
    categoriesLoading: state => state.categories.loading,
    categoriesOptions: state => state.categories.options,
    categoriesSearch: state => state.categories.search,
    categoriesAPL: state => state.categories.apl_id,

    markersItems: state => state.markers.items,
    markersTotal: state => state.markers.total,
    markersLoading: state => state.markers.loading,
    markersOptions: state => state.markers.options,
    markersSearch: state => state.markers.search,
    markersAPL: state => state.markers.apl_id,
    markersStatus: state => state.markers.status_id,
    markersCategory: state => state.markers.category_id,
    markersAvailability: state => state.markers.availability,

    userTypesItems: state => state.userTypes.items,
    userTypesTotal: state => state.userTypes.total,
    userTypesLoading: state => state.userTypes.loading,
    userTypesOptions: state => state.userTypes.options,
    userTypesSearch: state => state.userTypes.search,

    propertyGroupsItems: state => state.propertyGroups.items,
    propertyGroupsTotal: state => state.propertyGroups.total,
    propertyGroupsLoading: state => state.propertyGroups.loading,
    propertyGroupsOptions: state => state.propertyGroups.options,
    propertyGroupsSearch: state => state.propertyGroups.search,

    propertiesItems: state => state.properties.items,
    propertiesTotal: state => state.properties.total,
    propertiesLoading: state => state.properties.loading,
    propertiesOptions: state => state.properties.options,
    propertiesSearch: state => state.properties.search,

    currentAPL: state => state.currentAPL,

    dictionaryItems: state => state.dictionary.items,
    dictionaryKeys: state => state.dictionary.keys,
    dictionaryTotal: state => state.dictionary.total,
    dictionaryLoading: state => state.dictionary.loading,
    dictionaryOptions: state => state.dictionary.options,
    dictionarySearch: state => state.dictionary.search,

    languageItems: state => state.language.items,
    languageKeys: state => state.language.keys,
    languageTotal: state => state.language.total,
    languageLoading: state => state.language.loading,
    languageOptions: state => state.language.options,
    languageSearch: state => state.language.search,

    currentLanguage: state => state.currentLanguage,
    languages: state => state.language.items,
    languageCodes: state => state.language.items.map(lang => lang.languageCode),

    dictionaryGenerationComplete: state => state.dictionaryGeneration.complete,
    dictionaryGenerationLoading: state => state.dictionaryGeneration.loading,

    dictionaryImportProgressVisible: state => state.dictionaryImportProgress.visible,
    dictionaryImportProgressValue: state => state.dictionaryImportProgress.value,

    uniquesForApl: state => state.uniquesForApl
  },
  mutations: {
    setAuth (state, payload) {
      Vue.set(state.auth, 'user', payload.user)
      Vue.set(state.auth, 'token', payload.accessToken)
    },

    setLogItems (state, payload) {
      Vue.set(state.log, 'items', payload.data)
      Vue.set(state.log, 'totalPages', Math.ceil(payload.total / state.log.limit))
    },
    setLogItemsType (state, payload) {
      Vue.set(state.log, 'type', payload)
    },
    setLogItemsPage (state, payload) {
      Vue.set(state.log, 'page', payload)
    },
    setLogItemsLimit (state, payload) {
      Vue.set(state.log, 'limit', payload)
    },
    setLogSearch (state, payload) {
      Vue.set(state.log, 'search', payload)
    },

    setAPLsLoading (state, payload) {
      state.apls.loading = payload
    },
    setAPLs (state, payload) {
      if (payload.data) {
        state.apls.items = payload.data
        state.apls.total = payload.total
      } else {
        state.apls.items = payload
        state.apls.total = payload.length
      }
    },
    setAPLsOptions (state, payload) {
      state.apls.options = payload
    },
    setAPLsSearch (state, payload) {
      state.apls.search = payload
    },

    setStatusesLoading (state, payload) {
      state.statuses.loading = payload
    },
    setStatuses (state, payload) {
      if (payload.data) {
        state.statuses.items = payload.data
        state.statuses.total = payload.total
      } else {
        state.statuses.items = payload
        state.statuses.total = payload.length
      }
    },
    setStatusesOptions (state, payload) {
      state.statuses.options = payload
    },
    setStatusesSearch (state, payload) {
      state.statuses.search = payload
    },
    setStatusesApl (state, payload) {
      state.statuses.apl_id = payload
    },
    setStatusesCategory (state, payload) {
      state.statuses.category_id = payload
    },

    setCategoriesLoading (state, payload) {
      state.categories.loading = payload
    },
    setCategories (state, payload) {
      if (payload.data) {
        state.categories.items = payload.data
        state.categories.total = payload.total
      } else {
        state.categories.items = payload
        state.categories.total = payload.length
      }
    },
    setCategoriesOptions (state, payload) {
      state.categories.options = payload
    },
    setCategoriesSearch (state, payload) {
      state.categories.search = payload
    },
    setCategoriesApl (state, payload) {
      state.categories.apl_id = payload
    },

    setMarkersLoading (state, payload) {
      state.markers.loading = payload
    },
    setMarkers (state, payload) {
      if (payload.data) {
        state.markers.items = payload.data
        state.markers.total = payload.total
      } else {
        state.markers.items = payload
        state.markers.total = payload.length
      }
    },
    setMarkersOptions (state, payload) {
      state.markers.options = payload
    },
    setMarkersSearch (state, payload) {
      state.markers.search = payload
    },
    setMarkersApl (state, payload) {
      state.markers.apl_id = payload
    },
    setMarkersStatus (state, payload) {
      state.markers.status_id = payload
    },
    setMarkersCategory (state, payload) {
      state.markers.category_id = payload
    },
    setMarkersAvailability (state, payload) {
      state.markers.availability = payload
    },

    setUserTypesLoading (state, payload) {
      state.userTypes.loading = payload
    },
    setUserTypes (state, payload) {
      if (payload.data) {
        state.userTypes.items = payload.data
        state.userTypes.total = payload.total
      } else {
        state.userTypes.items = payload
        state.userTypes.total = payload.length
      }
    },
    setUserTypesOptions (state, payload) {
      state.userTypes.options = payload
    },
    setUserTypesSearch (state, payload) {
      state.userTypes.search = payload
    },

    setPropertyGroupsLoading (state, payload) {
      state.propertyGroups.loading = payload
    },
    setPropertyGroups (state, payload) {
      if (payload.data) {
        state.propertyGroups.items = payload.data
        state.propertyGroups.total = payload.total
      } else {
        state.propertyGroups.items = payload
        state.propertyGroups.total = payload.length
      }
    },
    setPropertyGroupsOptions (state, payload) {
      state.propertyGroups.options = payload
    },
    setPropertyGroupsSearch (state, payload) {
      state.propertyGroups.search = payload
    },

    setPropertiesLoading (state, payload) {
      state.properties.loading = payload
    },
    setProperties (state, payload) {
      if (payload.data) {
        state.properties.items = payload.data
        state.properties.total = payload.total
      } else {
        state.properties.items = payload
        state.properties.total = payload.length
      }
    },
    setPropertiesOptions (state, payload) {
      state.properties.options = payload
    },
    setPropertiesSearch (state, payload) {
      state.properties.search = payload
    },

    setCurrentAPL (state, payload) {
      state.currentAPL = payload
    },

    setDictionaryLoading (state, payload) {
      state.dictionary.loading = payload
    },
    setDictionary (state, payload) {
      if (payload.data) {
        state.dictionary.items = payload.data
        state.dictionary.total = payload.total
      } else {
        state.dictionary.items = payload
        state.dictionary.total = payload.length
      }
    },
    setDictionaryKeys (state, payload) {
      state.dictionary.keys = payload.map(term => term.key)
    },
    setDictionaryOptions (state, payload) {
      state.dictionary.options = payload
    },
    setDictionarySearch (state, payload) {
      state.dictionary.search = payload
    },

    setLanguageLoading (state, payload) {
      state.language.loading = payload
    },
    setLanguage (state, payload) {
      if (payload.data) {
        state.language.items = payload.data
        state.language.total = payload.total
      } else {
        state.language.items = payload
        state.language.total = payload.length
      }
    },
    setLanguageKeys (state, payload) {
      state.language.keys = payload.map(term => term.languageCode)
    },
    setLanguageOptions (state, payload) {
      state.language.options = payload
    },
    setLanguageSearch (state, payload) {
      state.language.search = payload
    },

    resetLogPage (state) {
      Vue.set(state.log.options, 'page', 1)
    },
    resetAplsPage (state) {
      Vue.set(state.apls.options, 'page', 1)
    },
    resetStatusesPage (state) {
      Vue.set(state.statuses.options, 'page', 1)
    },
    resetCategoriesPage (state) {
      Vue.set(state.categories.options, 'page', 1)
    },
    resetMarkersPage (state) {
      Vue.set(state.markers.options, 'page', 1)
    },
    resetUserTypesPage (state) {
      Vue.set(state.userTypes.options, 'page', 1)
    },
    resetPropertyGroupsPage (state) {
      Vue.set(state.propertyGroups.options, 'page', 1)
    },
    resetPropertiesPage (state) {
      Vue.set(state.properties.options, 'page', 1)
    },
    resetDictionaryPage (state) {
      Vue.set(state.dictionary.options, 'page', 1)
    },
    resetlanguagePage (state) {
      Vue.set(state.language.options, 'page', 1)
    },

    setCurrentLanguage (state, payload) {
      state.currentLanguage = payload
      localStorage.setItem('currentLanguage', JSON.stringify(payload))

      window.location.reload()
    },

    setDictionaryGenerationComplete (state, payload) {
      state.dictionaryGeneration.complete = payload
    },
    setDictionaryGenerationLoading (state, payload) {
      state.dictionaryGeneration.loading = payload
    },

    setDictionaryImportProgressValue (state, payload) {
      state.dictionaryImportProgress.value = payload
    },
    setDictionaryImportProgressVisible (state, payload) {
      state.dictionaryImportProgress.visible = payload
    },

    setUniquesForAPl (state, payload) {
      state.uniquesForApl = payload
    }
  },
  actions: {
    async reAuth ({ state, commit }) {
      try {
        const response = await state.feathers.reAuthenticate()
        commit('setAuth', response)
        this.dispatch('saveLogItem', { type: 'success', user: response.user.username, text: '(auth) Autentificare reusita!' })
        return true
      } catch (err) {
        console.log(err)
        return false
      }
    },
    login: _debounce(async function ({ state, commit }, payload) {
      try {
        const response = await state.feathers.authenticate({
          strategy: 'local',
          ...payload
        })
        commit('setAuth', response)
        this.dispatch('saveLogItem', { type: 'success', user: response.user.username, text: '(auth) Autentificare reusita!' })
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: payload.email, text: '(auth) Autentificare esuata!' })
      }
    }, 500),
    async logout ({ state, commit }) {
      this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(auth) Iesire din aplicatie.' })
      await state.feathers.logout()
      commit('setAuth', { user: null, accessToken: null })
    },

    fetchLog: _debounce(async function ({ state, commit }) {
      if (state.auth.user) {
        try {
          const query = {
            query: {
              $skip: (state.log.page - 1) * state.log.limit,
              $limit: state.log.limit,
              $sort: {
                createdAt: -1
              }
            }
          }
          if (state.log.type !== 'all') {
            query.query.type = state.log.type
          }
          if (state.log.search) {
            query.query.text = { $regex: state.log.search, $options: 'i' }
          }
          const response = await state.services.log.find(query)
          commit('setLogItems', response)
        } catch (err) {
          console.log(err)
          // this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(log) Eroare la citire log...' })
        }
      }
    }, 500),
    setLogItemsType ({ state, commit, dispatch }, payload) {
      this.commit('setLogItemsType', payload)
      this.dispatch('fetchLog')
    },
    setLogItemsPage ({ state, commit, dispatch }, payload) {
      this.commit('setLogItemsPage', payload)
      this.dispatch('fetchLog')
    },
    setLogItemsLimit ({ state, commit, dispatch }, payload) {
      this.commit('setLogItemsLimit', payload)
      this.dispatch('fetchLog')
    },
    setLogSearch ({ state, commit, dispatch }, payload) {
      this.commit('setLogSearch', payload)
      this.commit('resetLogPage')
      this.dispatch('fetchLog')
    },
    async saveLogItem ({ state, dispatch }, payload) {
      try {
        await state.services.log.create(payload)
        dispatch('fetchLog')
      } catch (err) {
        console.log(err)
      }
    },
    async deleteLogItem ({ state, dispatch }, payload) {
      try {
        await state.services.log.remove(payload)
        dispatch('fetchLog')
      } catch (err) {
        console.log(err)
      }
    },

    getAPL ({ state }, payload) {
      if (payload) {
        return state.services.apls.get(payload)
      }
      return {}
    },
    fetchAllAPLs ({ state }, payload) {
      const query = {
        $paginate: false
      }

      if (payload) {
        query.visible = true
      }

      return state.services.apls.find({ query })
    },
    fetchAPLs: _debounce(async function ({ state, commit }) {
      try {
        commit('setAPLsLoading', true)

        let query = {}

        if (state.auth.user && state.apls.options.itemsPerPage) {
          if (state.apls.search) {
            query.$or = [
              { name: { $regex: state.apls.search, $options: 'i' } },
              { county: { $regex: state.apls.search, $options: 'i' } }
            ]
          }

          if (state.apls.options.itemsPerPage !== -1) {
            query.$limit = state.apls.options.itemsPerPage
            query.$skip = (state.apls.options.page - 1) * state.apls.options.itemsPerPage
            query.$sort = state.apls.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.apls.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
          } else {
            query.$sort = state.apls.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.apls.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
            query.$paginate = false
          }
        } else {
          query = {
            visible: true,
            $paginate: false
          }
        }

        const result = await state.services.apls.find({ query })
        commit('setAPLs', result)
        commit('setAPLsLoading', false)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(APLs) Eroare la citire APL-uri...' })
      }
    }, 500),
    async saveAPL ({ state, commit, dispatch }, payload) {
      if (payload.logo.buffer instanceof File) {
        payload.buffer = await readFile(payload.logo.buffer)
        delete payload.logo.buffer
      }

      if (payload._id) {
        try {
          await state.services.apls.patch(payload._id, payload)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(APLs) Modificat APL: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(APLs) Eroare modificare APL: ' + err.message })
        }
      } else {
        try {
          const { _id, ...rest } = payload
          await state.services.apls.create(rest)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(APLs) Creat APL: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(APLs) Eroare creare APL: ' + err.message })
        }
      }
      dispatch('fetchAPLs')
    },
    async deleteAPL ({ state, commit, dispatch }, payload) {
      try {
        await state.services.apls.remove(payload)
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(APLs) Eroare stergere APL: ' + err.message })
      }
      dispatch('fetchAPLs')
    },
    setAPLsOptions ({ state, commit, dispatch }, payload) {
      commit('setAPLsOptions', payload)
      dispatch('fetchAPLs')
    },
    setAPLsSearch ({ state, commit, dispatch }, payload) {
      commit('setAPLsSearch', payload)
      commit('resetAplsPage')
      dispatch('fetchAPLs')
    },
    async importMarker ({ state, dispatch }, payload) {
      saverWorker.onmessage = null
      return new Promise((resolve, reject) => {
        const token = state.auth.token
        saverWorker.postMessage({ message: 'saveMarker', token, payload })
        saverWorker.onmessage = e => {
          dispatch('saveLogItem', { type: 'success', user: state.auth.user?.username ?? 'no user', text: '(import-marker) Importat marker: ' + payload.unique })
          resolve(e.result)
        }
        saverWorker.onerror = e => {
          dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(import-marker) Eroare la import...' + payload.unique })
          console.log(e)
        }
      })
    },

    fetchAllStatuses ({ state }, payload) {
      if (payload && payload.apl_id) {
        const query = {
          $paginate: false,
          apl_id: payload.apl_id,
          $populate: [
            { path: 'category_id', model: 'categories' }
          ],
          $sort: {
            category_id: 1
          }
        }

        return state.services.statuses.find({ query })
      } else {
        return state.services.statuses.find({
          query: {
            $paginate: false,
            $populate: [
              { path: 'category_id', model: 'categories' }
            ]
          }
        })
      }
    },
    fetchStatuses: _debounce(async function ({ state, commit }, payload = null) {
      try {
        commit('setStatusesLoading', true)

        let query = {
          $populate: [
            { path: 'apl_id', model: 'apls' },
            { path: 'category_id', model: 'categories' }
          ]
        }

        if (payload) {
          query.apl_id = payload
        }

        if (state.auth.user && state.statuses.options.itemsPerPage) {
          if (state.statuses.apl_id !== null) {
            query.apl_id = state.statuses.apl_id
          }
          if (state.statuses.category_id !== null) {
            query.category_id = state.statuses.category_id
          }

          if (state.statuses.search) {
            query.$or = [
              { name: { $regex: state.statuses.search, $options: 'i' } },
              { value: { $regex: state.statuses.search, $options: 'i' } }
            ]
          }

          if (state.statuses.options.itemsPerPage !== -1) {
            query.$limit = state.statuses.options.itemsPerPage
            query.$skip = (state.statuses.options.page - 1) * state.statuses.options.itemsPerPage
            query.$sort = state.statuses.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.statuses.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
          } else {
            query.$sort = state.statuses.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.statuses.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
            query.$paginate = false
          }
        } else {
          query = {
            $paginate: false
          }

          if (payload) {
            query.apl_id = payload
          }
        }

        const result = await state.services.statuses.find({ query })
        commit('setStatuses', result)
        commit('setStatusesLoading', false)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Statuses) Eroare la citire Statusuri...' })
      }
    }, 500),
    async saveStatus ({ state, commit, dispatch }, payload) {
      if (payload.icon.buffer instanceof File) {
        payload.buffer = await readFile(payload.icon.buffer)
        delete payload.icon.buffer
      }

      if (payload._id) {
        try {
          await state.services.statuses.patch(payload._id, payload)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Statuses) Modificat Status: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Statuses) Eroare modificare Status: ' + err.message })
        }
      } else {
        try {
          const { _id, ...rest } = payload
          await state.services.statuses.create(rest)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Statuses) Creat Status: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Statuses) Eroare creare Status: ' + err.message })
        }
      }
      dispatch('fetchStatuses')
    },
    async deleteStatus ({ state, commit, dispatch }, payload) {
      try {
        await state.services.statuses.remove(payload)
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Statuses) Eroare stergere Status: ' + err.message })
      }
      dispatch('fetchStatuses')
    },
    setStatusesOptions ({ state, commit, dispatch }, payload) {
      commit('setStatusesOptions', payload)
      dispatch('fetchStatuses')
    },
    setStatusesSearch ({ state, commit, dispatch }, payload) {
      commit('setStatusesSearch', payload)
      commit('resetStatusesPage')
      dispatch('fetchStatuses')
    },
    setStatusesApl ({ commit, dispatch }, payload) {
      commit('setStatusesApl', payload)
      dispatch('fetchStatuses')
    },
    setStatusesCategory ({ commit, dispatch }, payload) {
      commit('setStatusesCategory', payload)
      dispatch('fetchStatuses')
    },

    fetchAllCategories ({ state }, payload) {
      if (payload && payload.apl_id) {
        const query = {
          $paginate: false,
          apl_id: payload.apl_id
        }

        return state.services.categories.find({ query })
      } else {
        return state.services.categories.find({ query: { $paginate: false, $sort: { apl_id: 1 } } })
      }
    },
    fetchCategories: _debounce(async function ({ state, commit }, payload = null) {
      try {
        commit('setCategoriesLoading', true)

        let query = {
          $populate: [
            { path: 'apl_id', model: 'apls' }
          ]
        }

        if (payload) {
          query.apl_id = payload
        }

        if (state.auth.user && state.categories.options.itemsPerPage) {
          if (state.categories.search) {
            query.$or = [
              { name: { $regex: state.categories.search, $options: 'i' } },
              { value: { $regex: state.categories.search, $options: 'i' } }
            ]
          }
          if (state.categories.apl_id !== null) {
            query.apl_id = state.categories.apl_id
          }

          if (state.categories.options.itemsPerPage !== -1) {
            query.$limit = state.categories.options.itemsPerPage
            query.$skip = (state.categories.options.page - 1) * state.categories.options.itemsPerPage
            query.$sort = state.categories.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.categories.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
          } else {
            query.$sort = state.categories.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.categories.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
            query.$paginate = false
          }
        } else {
          query = {
            $paginate: false
          }

          if (payload) {
            query.apl_id = payload
          }
        }

        const result = await state.services.categories.find({ query })
        commit('setCategories', result)
        commit('setCategoriesLoading', false)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Categories) Eroare la citire Categorii...' })
      }
    }, 500),
    async saveCategory ({ state, commit, dispatch }, payload) {
      if (payload.icon.buffer instanceof File) {
        payload.buffer = await readFile(payload.icon.buffer)
        delete payload.icon.buffer
      }

      if (payload._id) {
        try {
          await state.services.categories.patch(payload._id, payload)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Categories) Modificat Categorie: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Categories) Eroare modificare Categorie: ' + err.message })
        }
      } else {
        try {
          const { _id, ...rest } = payload
          await state.services.categories.create(rest)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Categories) Creat Categorie: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Categories) Eroare creare Categorie: ' + err.message })
        }
      }
      dispatch('fetchCategories')
    },
    async deleteCategory ({ state, commit, dispatch }, payload) {
      try {
        await state.services.categories.remove(payload)
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Categories) Eroare stergere Categorie: ' + err.message })
      }
      dispatch('fetchCategories')
    },
    setCategoriesOptions ({ state, commit, dispatch }, payload) {
      commit('setCategoriesOptions', payload)
      dispatch('fetchCategories')
    },
    setCategoriesSearch ({ state, commit, dispatch }, payload) {
      commit('setCategoriesSearch', payload)
      commit('resetCategoriesPage')
      dispatch('fetchCategories')
    },
    setCategoriesApl ({ commit, dispatch }, payload) {
      commit('setCategoriesApl', payload)
      dispatch('fetchCategories')
    },

    fetchAllMarkers ({ state }, payload) {
      if (payload && payload.apl_id) {
        const query = {
          $paginate: false,
          apl_id: payload.apl_id,
          $populate: [
            { path: 'apl_id', model: 'apls' },
            { path: 'status_id', model: 'statuses' },
            { path: 'category_id', model: 'categories' }
          ]
        }

        if (payload.status_id) {
          query.status_id = payload.status_id
        }
        if (payload.category_id) {
          query.category_id = payload.category_id
        }

        return state.services.markers.find({ query })
      }
      return []
    },
    fetchMarkers: _debounce(async function ({ state, commit }, payload = null) {
      try {
        commit('setMarkersLoading', true)

        const collation = {
          locale: 'ro',
          numericOrdering: true
        }

        const query = {
          $collation: collation,
          $populate: [
            { path: 'apl_id', model: 'apls' },
            { path: 'status_id', model: 'statuses' },
            { path: 'category_id', model: 'categories' }
          ]
        }

        if (payload) {
          query.apl_id = payload.apl_id
        }

        if (state.auth.user && state.markers.options.itemsPerPage) {
          if (state.markers.apl_id !== null) {
            query.apl_id = state.markers.apl_id
          }

          if (state.markers.status_id !== null) {
            query.status_id = state.markers.status_id
          }

          if (state.markers.category_id !== null) {
            query.category_id = state.markers.category_id
          }

          if (state.markers.availability !== null) {
            query.availability = state.markers.availability
          }

          if (state.markers.search) {
            query.$or = [
              { address: { $regex: state.markers.search, $options: 'i' } },
              { unique: { $regex: state.markers.search, $options: 'i' } },
              { unique_apl: { $regex: state.markers.search, $options: 'i' } },
              { status: { $regex: state.markers.search, $options: 'i' } },
              { category: { $regex: state.markers.search, $options: 'i' } }
            ]
          }

          if (state.markers.options.itemsPerPage !== -1) {
            query.$limit = state.markers.options.itemsPerPage
            query.$skip = (state.markers.options.page - 1) * state.markers.options.itemsPerPage
            query.$sort = state.markers.options.sortBy.reduce((acc, field, index) => {
              let fld = field

              if (field.includes('.')) {
                fld = field.substring(0, field.indexOf('.'))
              }

              acc[fld] = state.markers.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
          } else {
            query.$sort = state.markers.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.markers.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
            query.$paginate = false
          }
        } else {
          query.$limit = 10
          query.$skip = 0
        }

        const result = await state.services.markers.find({ query })
        commit('setMarkers', result)
        commit('setMarkersLoading', false)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Markers) Eroare la citire Markere...' })
      }
    }, 500),
    async saveMarker ({ state, commit, dispatch }, payload) {
      if (payload._id) {
        try {
          await state.services.markers.patch(payload._id, payload)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: `(Markers) Modificat Marker: ${payload.unique_apl}-${payload.unique} ${payload.address}` })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Markers) Eroare modificare Marker: ' + err.message })
        }
      } else {
        try {
          const { _id, ...rest } = payload
          await state.services.markers.create(rest)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: `(Markers) Creat Marker: ${payload.unique_apl}-${payload.unique} ${payload.address}` })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Markers) Eroare creare Marker: ' + err.message })
        }
      }
      dispatch('fetchMarkers')
    },
    async deleteMarker ({ state, commit, dispatch }, payload) {
      try {
        await state.services.markers.remove(payload)
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Markers) Eroare stergere Marker: ' + err.message })
      }
      dispatch('fetchMarkers')
    },
    setMarkersOptions ({ commit, dispatch }, payload) {
      commit('setMarkersOptions', payload)
      dispatch('fetchMarkers')
    },
    setMarkersSearch ({ commit, dispatch }, payload) {
      commit('setMarkersSearch', payload)
      commit('resetMarkersPage')
      dispatch('fetchMarkers')
    },
    setMarkersApl ({ commit, dispatch }, payload) {
      commit('setMarkersApl', payload)
      dispatch('fetchMarkers')
    },
    setMarkersStatus ({ commit, dispatch }, payload) {
      commit('setMarkersStatus', payload)
      dispatch('fetchMarkers')
    },
    setMarkersCategory ({ commit, dispatch }, payload) {
      commit('setMarkersCategory', payload)
      dispatch('fetchMarkers')
    },
    setMarkersAvailability ({ commit, dispatch }, payload) {
      commit('setMarkersAvailability', payload)
      dispatch('fetchMarkers')
    },
    downloadMarkers ({ state }) {
      const query = {
        $paginate: false,
        $populate: [
          { path: 'apl_id', model: 'apls' },
          { path: 'status_id', model: 'statuses' }
        ]
      }

      if (state.auth.user && state.markers.options.itemsPerPage) {
        if (state.markers.apl_id !== null) {
          query.apl_id = state.markers.apl_id
        }

        if (state.markers.status_id !== null) {
          query.status_id = state.markers.status_id
        }

        if (state.markers.availability !== null) {
          query.availability = state.markers.availability
        }

        if (state.markers.search) {
          query.$or = [
            { address: { $regex: state.markers.search, $options: 'i' } },
            { unique: { $regex: state.markers.search, $options: 'i' } },
            { unique_apl: { $regex: state.markers.search, $options: 'i' } },
            { status: { $regex: state.markers.search, $options: 'i' } }
          ]
        }
      }

      return state.services.markers.find({ query })
    },

    fetchUserTypes: _debounce(async function ({ state, commit }) {
      try {
        commit('setUserTypesLoading', true)

        let query = {}

        if (state.auth.user && state.userTypes.options.itemsPerPage) {
          if (state.userTypes.search) {
            query.$or = [
              { title: { $regex: state.userTypes.search, $options: 'i' } },
              { description: { $regex: state.userTypes.search, $options: 'i' } }
            ]
          }

          if (state.userTypes.options.itemsPerPage !== -1) {
            query.$limit = state.userTypes.options.itemsPerPage
            query.$skip = (state.userTypes.options.page - 1) * state.userTypes.options.itemsPerPage
            query.$sort = state.userTypes.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.userTypes.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
          } else {
            query.$sort = state.userTypes.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.userTypes.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
            query.$paginate = false
          }
        } else {
          query = {
            $paginate: false
          }
        }

        const result = await state.services.userTypes.find({ query })
        commit('setUserTypes', result)
        commit('setUserTypesLoading', false)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(UserTypes) Eroare la citire User Types...' })
      }
    }, 500),
    async saveUserType ({ state, commit, dispatch }, payload) {
      if (payload._id) {
        try {
          await state.services.userTypes.patch(payload._id, payload)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(UserTypes) Modificat User Type: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(UserTypes) Eroare modificare User Type: ' + err.message })
        }
      } else {
        try {
          const { _id, ...rest } = payload
          await state.services.userTypes.create(rest)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(UserTypes) Creat User Type: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(UserTypes) Eroare creare User Type: ' + err.message })
        }
      }
      dispatch('fetchUserTypes')
    },
    async deleteUserType ({ state, commit, dispatch }, payload) {
      try {
        await state.services.userTypes.remove(payload)
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(UserTypes) Eroare stergere UserType: ' + err.message })
      }
      dispatch('fetchUserTypes')
    },
    setUserTypesOptions ({ state, commit, dispatch }, payload) {
      commit('setUserTypesOptions', payload)
      dispatch('fetchUserTypes')
    },
    setUserTypesSearch ({ state, commit, dispatch }, payload) {
      commit('setUserTypesSearch', payload)
      commit('resetUserTypesPage')
      dispatch('fetchUserTypes')
    },

    fetchPropertyGroups: _debounce(async function ({ state, commit }) {
      try {
        commit('setPropertyGroupsLoading', true)

        let query = {
          $populate: [
            { path: 'apl_id', model: 'apls' }]
        }

        if (state.auth.user && state.propertyGroups.options.itemsPerPage) {
          if (state.propertyGroups.search) {
            query.$or = [
              { label: { $regex: state.propertyGroups.search, $options: 'i' } },
              { info: { $regex: state.propertyGroups.search, $options: 'i' } }
            ]
          }

          if (state.propertyGroups.options.itemsPerPage !== -1) {
            query.$limit = state.propertyGroups.options.itemsPerPage
            query.$skip = (state.propertyGroups.options.page - 1) * state.propertyGroups.options.itemsPerPage
            query.$sort = state.propertyGroups.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.propertyGroups.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
          } else {
            query.$sort = state.propertyGroups.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.propertyGroups.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
            query.$paginate = false
          }
        } else {
          query = {
            $paginate: false
          }
        }

        const result = await state.services.propertyGroups.find({ query })
        commit('setPropertyGroups', result)
        commit('setPropertyGroupsLoading', false)
      } catch (err) {
        console.log(err)
        commit('setPropertyGroupsLoading', false)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(PropertyGroups) Eroare la citire Property Group...' })
      }
    }, 500),
    fetchAllPropertyGroups ({ state }, payload) {
      const query = {
        $paginate: false,
        $populate: [
          { path: 'apl_id', model: 'apls' }]
      }

      if (payload) {
        query.visible = true
      }

      return state.services.propertyGroups.find({ query })
    },
    async savePropertyGroup ({ state, commit, dispatch }, payload) {
      if (payload.icon.buffer instanceof File) {
        payload.buffer = await readFile(payload.icon.buffer)
        delete payload.icon.buffer
      }

      if (payload._id) {
        try {
          await state.services.propertyGroups.patch(payload._id, payload)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(PropertyGroups) Modificat Property Group: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(PropertyGroups) Eroare modificare Property Group: ' + err.message })
        }
      } else {
        try {
          const { _id, ...rest } = payload
          await state.services.propertyGroups.create(rest)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(PropertyGroups) Creat Property Group: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(PropertyGroups) Eroare creare Property Group: ' + err.message })
        }
      }
      dispatch('fetchPropertyGroups')
    },
    async deletePropertyGroup ({ state, commit, dispatch }, payload) {
      try {
        await state.services.propertyGroups.remove(payload)
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(PropertyGroups) Eroare stergere Property Group: ' + err.message })
      }
      dispatch('fetchPropertyGroups')
    },
    setPropertyGroupsOptions ({ state, commit, dispatch }, payload) {
      commit('setPropertyGroupsOptions', payload)
      dispatch('fetchPropertyGroups')
    },
    setPropertyGroupsSearch ({ state, commit, dispatch }, payload) {
      commit('setPropertyGroupsSearch', payload)
      commit('resetPropertyGroupsPage')
      dispatch('fetchPropertyGroups')
    },

    async fetchAllPropertiesForApl ({ state }, payload) {
      if (payload && payload.apl_id) {
        const groups = await state.services.propertyGroups.find({
          query: {
            $paginate: false,
            apl_id: payload.apl_id,
            $sort: { index: 1 }
          }
        })

        if (groups.length) {
          for (const group of groups) {
            group.properties = await state.services.properties.find({
              query: {
                $paginate: false,
                propertygroup_id: group._id,
                $sort: { index: 1 }
              }
            })
          }

          return groups
        }
      } else {
        return []
      }
    },
    fetchProperties: _debounce(async function ({ state, commit }) {
      try {
        commit('setPropertiesLoading', true)

        let query = {
          $populate: [
            {
              path: 'propertygroup_id',
              model: 'propertygroups',
              populate: [{ path: 'apl_id', model: 'apls' }]
            }
          ]
        }

        if (state.auth.user && state.properties.options.itemsPerPage) {
          if (state.properties.search) {
            query.$or = [
              { label: { $regex: state.properties.search, $options: 'i' } },
              { info: { $regex: state.properties.search, $options: 'i' } }
            ]
          }

          if (state.properties.options.itemsPerPage !== -1) {
            query.$limit = state.properties.options.itemsPerPage
            query.$skip = (state.properties.options.page - 1) * state.properties.options.itemsPerPage
            query.$sort = state.properties.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.properties.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
          } else {
            query.$sort = state.properties.options.sortBy.reduce((acc, field, index) => {
              acc[field] = state.properties.options.sortDesc[index] ? -1 : 1
              return acc
            }, {})
            query.$paginate = false
          }
        } else {
          query = {
            $paginate: false
          }
        }

        const result = await state.services.properties.find({ query })
        commit('setProperties', result)
        commit('setPropertiesLoading', false)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Properties) Eroare la citire Property Group...' })
      }
    }, 500),
    async saveProperty ({ state, commit, dispatch }, payload) {
      if (payload.icon.buffer instanceof File) {
        payload.buffer = await readFile(payload.icon.buffer)
        delete payload.icon.buffer
      }

      if (payload._id) {
        try {
          await state.services.properties.patch(payload._id, payload)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Properties) Modificat Property: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Properties) Eroare modificare Property: ' + err.message })
        }
      } else {
        try {
          const { _id, ...rest } = payload
          await state.services.properties.create(rest)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Properties) Creat Property: ' + payload.name })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Properties) Eroare creare Property: ' + err.message })
        }
      }
      dispatch('fetchProperties')
    },
    async deleteProperty ({ state, commit, dispatch }, payload) {
      try {
        await state.services.properties.remove(payload)
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Properties) Eroare stergere Property Group: ' + err.message })
      }
      dispatch('fetchProperties')
    },
    setPropertiesOptions ({ state, commit, dispatch }, payload) {
      commit('setPropertiesOptions', payload)
      dispatch('fetchProperties')
    },
    setPropertiesSearch ({ state, commit, dispatch }, payload) {
      commit('setPropertiesSearch', payload)
      commit('resetPropertiesPage')
      dispatch('fetchProperties')
    },

    async fetchMarkerDetails ({ state }, payload) {
      if (payload && payload._id) {
        try {
          const details = await state.services.details.find({
            query: {
              $paginate: false,
              marker_id: payload._id
            }
          })

          return details[0] || {}
        } catch (err) {
          console.log(err)
          return {}
        }
      } else {
        return {}
      }
    },

    /**
     * ! can't expect debounce to return a promise
    */
    fetchProperties4Marker: _debounce(async function ({ state }, payload) {
      try {
        const pgQuery = {
          $paginate: false,
          apl_id: payload.apl_id._id,
          $sort: {
            index: 1
          }
        }

        const pgResult = await state.services.propertyGroups.find({ query: pgQuery })

        if (pgResult.length) {
          const pQuery = {
            $paginate: false,
            propertygroup_id: {
              $in: pgResult.map(item => item._id)
            },
            $sort: {
              index: 1
            }
          }

          const pResult = await state.services.properties.find({ query: pQuery })

          if (pResult) {
            pgResult.forEach(group => {
              group.properties = []

              pResult.forEach(prop => {
                if (prop.propertygroup_id === group._id) {
                  group.properties.push(prop)
                }
              })
            })
          }

          return pgResult
        } else {
          return []
        }
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Marker) Eroare la citire Proprietati...' })
      }
    }, 500),
    async saveDetails ({ state }, payload) {
      try {
        const { data, properties } = payload
        const obj = {
          marker_id: data.marker_id
        }

        if (data._id) {
          obj._id = data._id
        }
        // check for image/document buffers
        // read files and attach to api payload
        for (const group of properties) {
          obj[group.label] = {}
          for (const prop of group.properties) {
            if (prop.type === 'image' || prop.type === 'document') {
              if (data[group.label][prop.label].buffer instanceof File) {
                const buffer = await readFile(data[group.label][prop.label].buffer)
                obj[group.label][prop.label] = {
                  value: data[group.label][prop.label].value,
                  buffer
                }
              } else {
                obj[group.label][prop.label] = data[group.label][prop.label].value
              }
            } else {
              obj[group.label][prop.label] = data[group.label][prop.label].value
            }
          }
        }
        // call service method
        if (obj._id) {
          try {
            // PATCH OVEWRITES SUBDOCUMENTS
            // try using dot notaition instead: 'Detalii.short text'
            const result = await state.services.details.patch(obj._id, obj)
            this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Details) Modificat Detalii marker ' + obj._id })
            return result
          } catch (err) {
            this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Details) Eroare modificare Detalii marker ' + err.message })
            return false
          }
        } else {
          try {
            const result = await state.services.details.create(obj)
            this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Details) Creat Detalii marker ' + result._id })
            return result
          } catch (err) {
            this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Details) Eroare creare Detalii marker ' + err.message })
            return false
          }
        }
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: `(Detalii) Eroare la salvare Detalii marker ${payload.marker_id}...` })
      }
    },
    /**
     * ! can't expect debounce to return a promise
    */
    fetchDetails: _debounce(async function ({ state }, payload) {
      try {
        const result = await state.services.details.find({ query: { marker_id: payload } })
        return result.data[0]
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Details) Eroare la citire Detalii marker...' })
        return false
      }
    }, 500),

    // to be removed
    async fetchPics ({ state }, payload) {
      try {
        const result = await state.services.rest.get(`${payload.id}:::${payload.index}`)
        return result
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: `(Poze) Eroare la citire poze pentru marker ${payload}` })
        return false
      }
    },

    fetchDictionary: _debounce(async function ({ state, commit }) {
      try {
        commit('setDictionaryLoading', true)

        const query = { }

        if (state.dictionary.search) {
          query.$or = [
            { key: { $regex: state.dictionary.search, $options: 'i' } },
            { description: { $regex: state.dictionary.search, $options: 'i' } }
          ]
        }

        if (state.dictionary.options.itemsPerPage !== -1) {
          query.$limit = state.dictionary.options.itemsPerPage
          query.$skip = (state.dictionary.options.page - 1) * state.dictionary.options.itemsPerPage
          query.$sort = state.dictionary.options.sortBy.reduce((acc, field, index) => {
            acc[field] = state.dictionary.options.sortDesc[index] ? -1 : 1
            return acc
          }, {})
        } else {
          query.$sort = state.dictionary.options.sortBy.reduce((acc, field, index) => {
            acc[field] = state.dictionary.options.sortDesc[index] ? -1 : 1
            return acc
          }, {})
          query.$paginate = false
        }

        const result = await state.services.dictionary.find({ query })
        commit('setDictionary', result)
        commit('setDictionaryLoading', false)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Dictionary) Eroare la citire Dictionar...' })
      }
    }, 500),
    fetchDictionaryKeys: _debounce(async function ({ state, commit }) {
      try {
        commit('setDictionaryLoading', true)

        const query = { $select: ['key'], $paginate: false }

        const result = await state.services.dictionary.find({ query })
        commit('setDictionaryKeys', result)
        commit('setDictionaryLoading', false)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Dictionary) Eroare la citire Dictionar...' })
      }
    }, 500),
    async saveDictionary ({ state, commit, dispatch }, payload) {
      if (payload._id) {
        try {
          await state.services.dictionary.patch(payload._id, payload)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Dictionary) Modificat Dictionar: ' + payload.key })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Dictionary) Eroare modificare Dictionar: ' + err.message })
        }
      } else {
        try {
          const { _id, ...rest } = payload
          await state.services.dictionary.create(rest)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Dictionary) Creat Dictionar: ' + payload.key })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Dictionary) Eroare creare Dictionar: ' + err.message })
        }
      }
      dispatch('fetchDictionary')
      dispatch('fetchDictionaryKeys')
      dispatch('generateDictionaryJSON')
    },
    async deleteDictionary ({ state, commit, dispatch }, payload) {
      try {
        await state.services.dictionary.remove(payload)
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Dictionary) Eroare stergere Dictionar: ' + err.message })
      }
      dispatch('fetchDictionary')
      dispatch('fetchDictionaryKeys')
      dispatch('generateDictionaryJSON')
    },
    setDictionaryOptions ({ state, commit, dispatch }, payload) {
      commit('setDictionaryOptions', payload)
      // this gets triggered before the component is mounted
      dispatch('fetchDictionary')
      dispatch('fetchDictionaryKeys')
    },
    setDictionarySearch ({ state, commit, dispatch }, payload) {
      commit('setDictionarySearch', payload)
      commit('resetDictionaryPage')
      dispatch('fetchDictionary')
      dispatch('fetchDictionaryKeys')
    },
    async importDictionary ({ state, commit, dispatch }, payload) {
      const total = payload.length
      let current = 0
      let progress = Number(current * total / 100).toFixed(2)

      commit('setDictionaryImportProgressVisible', true)
      commit('setDictionaryImportProgressValue', progress)

      for (const term of payload) {
        current++
        try {
          const resp = await state.services.dictionary.patch(null, term, {
            query: {
              key: term.key,
              $mongoose: {
                upsert: true
              }
            }
          })
          dispatch('saveLogItem', { type: 'success', user: state.auth.user?.username ?? 'no user', text: '(import-dictionary) Importat Dictionar, create: ' + JSON.stringify(resp) })
        } catch (err) {
          dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(import-dictionary) Eroare la import... ' + JSON.stringify(err) })
        } finally {
          progress = Number(current * 100 / total).toFixed(2)
          commit('setDictionaryImportProgressValue', progress)
        }
      }
      dispatch('fetchDictionary')
      dispatch('generateDictionaryJSON')
      commit('setDictionaryImportProgressValue', 0)
      commit('setDictionaryImportProgressVisible', false)

      return 'done'
    },
    generateDictionaryJSON: _debounce(async function ({ state }) {
      state.services.i18n.create({ some: 'dummy', object: 'here' })
    }, 1000),
    generateDictionaryXLSX: _debounce(async function ({ state, commit }) {
      commit('setDictionaryGenerationLoading', true)
      commit('setDictionaryGenerationComplete', false)

      try {
        const generated = await state.services.i18n.get('dummy')
        commit('setDictionaryGenerationLoading', false)
        commit('setDictionaryGenerationComplete', generated)
      } catch (err) {
        console.log(err)
        commit('setDictionaryGenerationLoading', false)
        commit('setDictionaryGenerationComplete', false)
      }
    }, 1000),

    fetchLanguage: _debounce(async function ({ state, commit }) {
      try {
        const result = await state.services.language.find({ query: { $paginate: false } })
        commit('setLanguage', result)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Language) Eroare la citire Limbi...' })
      }
    }, 500),
    fetchLanguageKeys: _debounce(async function ({ state, commit }) {
      try {
        commit('setLanguageLoading', true)

        const query = { $select: ['languageCode'], $paginate: false }

        const result = await state.services.language.find({ query })
        commit('setLanguageKeys', result)
        commit('setLanguageLoading', false)
      } catch (err) {
        console.log(err)
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user?.username ?? 'no user', text: '(Language) Eroare la citire Limba...' })
      }
    }, 500),
    async saveLanguages ({ state, commit, dispatch }, payload) {
      // use to create multiple languages when importing from dictionary xlsx
      if (payload.length) {
        try {
          for (const newLang of payload) {
            await state.services.language.create({
              title: newLang.lang.toUpperCase(),
              countryCode: newLang.lang.toUpperCase(),
              languageCode: newLang.lang
            })
          }
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Language) Adaugat Limba: ' + payload.languageCode })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Language) Eroare adaugare Limba: ' + err.message })
        }
      }
      dispatch('fetchLanguage')
      dispatch('generateDictionaryJSON')
    },
    async saveLanguage ({ state, commit, dispatch }, payload) {
      if (payload._id) {
        try {
          await state.services.language.patch(payload._id, payload)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Language) Modificat Limba: ' + payload.title })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Language) Eroare modificare Limba: ' + err.message })
        }
      } else {
        try {
          const { _id, ...rest } = payload
          await state.services.language.create(rest)
          this.dispatch('saveLogItem', { type: 'success', user: state.auth.user.username, text: '(Language) Creat Limba: ' + payload.title })
        } catch (err) {
          this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Language) Eroare creare Limba: ' + err.message })
        }
      }
      dispatch('fetchLanguage')
      dispatch('generateDictionaryJSON')
    },
    async deleteLanguage ({ state, commit, dispatch }, payload) {
      try {
        await state.services.language.remove(payload)
      } catch (err) {
        this.dispatch('saveLogItem', { type: 'error', user: state.auth.user.username, text: '(Language) Eroare stergere Limba: ' + err.message })
      }
      dispatch('fetchLanguage')
    },
    setLanguageOptions ({ state, commit, dispatch }, payload) {
      commit('setLanguageOptions', payload)
      // this gets triggered before the component is mounted
      dispatch('fetchLanguage')
      dispatch('fetchLanguageKeys')
    },
    setLanguageSearch ({ state, commit, dispatch }, payload) {
      commit('setLanguageSearch', payload)
      commit('resetLanguagePage')
      dispatch('fetchLanguage')
      dispatch('fetchLanguageKeys')
    },

    setupSaverWorker ({ state }, payload) {
      if (saverWorker) {
        saverWorker.terminate()
        saverWorker = null
      }

      if (payload) {
        saverWorker = new Worker(new URL('../workers/saver.worker.js', import.meta.url))
      }

      return true
    },

    async createGeojson ({ state }, payload) {
      await state.services.geojson.create({ map_id: payload })
    },

    async fetchUniquesForApl ({ state, commit }, payload) {
      if (payload) {
        const result = await state.services.markers.find({
          query: {
            $paginate: false,
            $select: ['unique'],
            apl_id: payload
          }
        })

        commit('setUniquesForAPl', Object.freeze(result))
      } else {
        commit('setUniquesForAPl', [])
      }
    }
  }
})

let saverWorker = null

const reader = new FileReader()

function readFile (file) {
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file)

    reader.onload = e => {
      const base64 = e.target.result.split('base64,')[1]
      resolve(base64)
    }

    reader.onerror = event => {
      reader.abort()
      reject(new Error('file reader error'))
    }
  })
}
